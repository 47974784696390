import React from 'react';
import Sectitle from './Title/Sectitle';
import Slider from 'react-slick';

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
};
const ScreenshowCase =()=>{
    return(
        <section  className="app_screenshot_area sec_pad" id="showcase">
            <div  className="container custom_container p0">
                <Sectitle sClass="sec_title text-center mb_70" Title="Showcase" tClass="t_color3 mb_20" TitleP="Le dashboard di Consolidata sono molteplici, da quelle verticali per un singolo canale o brand a quelle di overview multicanale o multibrand. E se le visualizzazioni standard non ti bastano, il nostro Staff sarà lieto di costruire con te una dashboard su misura per le tue esigenze"/>
                <div  className="app_screen_info">
                    <Slider  className="app_screenshot_slider" {...settings}>
                        <div  className="item">
                            <div  className="screenshot_img">
                                <a href="img/bigeye/homepage.jpeg" onClick={ e => e.preventDefault() } className="image-link"><img src={require('../img/bigeye/homepage.jpeg')} alt=""/></a>
                            </div>
                        </div>
                        <div  className="item">
                            <div  className="screenshot_img">
                                <a href="img/bigeye/overview.jpeg" onClick={ e => e.preventDefault() }  className="image-link"><img src={require('../img/bigeye/overview.jpeg')} alt=""/></a>
                            </div>
                        </div>
                        <div  className="item">
                            <div  className="screenshot_img">
                                <a href="img/bigeye/overview2.jpeg" onClick={ e => e.preventDefault() }  className="image-link"><img src={require('../img/bigeye/overview2.jpeg')} alt=""/></a>
                            </div>
                        </div>
                        <div  className="item">
                            <div  className="screenshot_img">
                                <a href="img/bigeye/overview3.jpeg" onClick={ e => e.preventDefault() }  className="image-link"><img src={require('../img/bigeye/overview3.jpeg')} alt=""/></a>
                            </div>
                        </div>
                        <div  className="item">
                            <div  className="screenshot_img">
                                <a href="img/bigeye/display.jpeg" onClick={ e => e.preventDefault() }  className="image-link"><img src={require('../img/bigeye/display.jpeg')} alt=""/></a>
                            </div>
                        </div>
                        <div  className="item">
                            <div  className="screenshot_img">
                                <a href="img/bigeye/display2.jpeg" onClick={ e => e.preventDefault() }  className="image-link"><img src={require('../img/bigeye/display2.jpeg')} alt=""/></a>
                            </div>
                        </div>
                        <div  className="item">
                            <div  className="screenshot_img">
                                <a href="img/bigeye/display3.jpeg" onClick={ e => e.preventDefault() }  className="image-link"><img src={require('../img/bigeye/display3.jpeg')} alt=""/></a>
                            </div>
                        </div>
                        <div  className="item">
                            <div  className="screenshot_img">
                                <a href="img/bigeye/display4.jpeg" onClick={ e => e.preventDefault() }  className="image-link"><img src={require('../img/bigeye/display4.jpeg')} alt=""/></a>
                            </div>
                        </div>
                        <div  className="item">
                            <div  className="screenshot_img">
                                <a href="img/bigeye/search.jpeg" onClick={ e => e.preventDefault() }  className="image-link"><img src={require('../img/bigeye/search.jpeg')} alt=""/></a>
                            </div>
                        </div>
                        <div  className="item">
                            <div  className="screenshot_img">
                                <a href="img/bigeye/search2.jpeg" onClick={ e => e.preventDefault() }  className="image-link"><img src={require('../img/bigeye/search2.jpeg')} alt=""/></a>
                            </div>
                        </div>
                        <div  className="item">
                            <div  className="screenshot_img">
                                <a href="img/bigeye/search3.jpeg" onClick={ e => e.preventDefault() }  className="image-link"><img src={require('../img/bigeye/search3.jpeg')} alt=""/></a>
                            </div>
                        </div>
                        <div  className="item">
                            <div  className="screenshot_img">
                                <a href="img/bigeye/social.jpeg" onClick={ e => e.preventDefault() }  className="image-link"><img src={require('../img/bigeye/social.jpeg')} alt=""/></a>
                            </div>
                        </div>
                        <div  className="item">
                            <div  className="screenshot_img">
                                <a href="img/bigeye/social2.jpeg" onClick={ e => e.preventDefault() }  className="image-link"><img src={require('../img/bigeye/social2.jpeg')} alt=""/></a>
                            </div>
                        </div>
                        <div  className="item">
                            <div  className="screenshot_img">
                                <a href="img/bigeye/tv.jpeg" onClick={ e => e.preventDefault() }  className="image-link"><img src={require('../img/bigeye/tv.jpeg')} alt=""/></a>
                            </div>
                        </div>
                        <div  className="item">
                            <div  className="screenshot_img">
                                <a href="img/bigeye/tv2.jpeg" onClick={ e => e.preventDefault() }  className="image-link"><img src={require('../img/bigeye/tv2.jpeg')} alt=""/></a>
                            </div>
                        </div>
                    </Slider>
                </div>
            </div>
        </section>
    )
}
export default ScreenshowCase;
