import React from 'react';
import SeoTitle from '../Title/SeoTitle';
import Fade from 'react-reveal/Fade';

const Service = () => {
    return(
        <React.Fragment>
            <section id="features" className="seo_service_area sec_pad">
                <div className="container">
                    <SeoTitle Title='Caratteristiche' TitleP='BigEye di Consolidata è un approccio, un metodo di gestione, non un semplice strumento. Grazie ad esso è possibile approvvigionarsi di dati consistenti, arricchiti di meta-informazioni business oriented visualizzabili comodamente su dashboard via web'/>
                    <div className="row seo_service_info">
                        <Fade bottom duration={500}>
                            <div className="col-lg-4 col-md-6">
                                <div className="seo_service_item">
                                    <img src={require('../../img/seo/icon1.png')} alt=""/>
                                    <a href=".#">
                                        <h4>Omni-channel</h4>
                                    </a>
                                    <p>Supportiamo tutti i principali dati di marketing: Online (display, mobile, video, social, search, audience, ad verification, ecommerce), Offline (TV, Radio, Stampa, OOH e DOOH), Owned Data (Analytics, SEO), Third-party Data (Competition, Investimenti, ecc)</p>
                                </div>
                            </div>
                        </Fade>
                        <Fade bottom duration={700} >
                            <div className="col-lg-4 col-md-6">
                                <div className="seo_service_item">
                                    <img src={require('../../img/seo/icon2.png')} alt=""/>
                                    <a href=".#">
                                        <h4>Completo</h4>
                                    </a>
                                    <p>BigEye di Consolidata si occupa di tutto il ciclo di vita del dato, dall'approvvigionamento (tramite API, database, file CSV, ecc), alla trasformazione, analisi e visualizzazione su dashboard</p>
                                </div>
                            </div>
                        </Fade>
                        <Fade bottom duration={1000}>
                            <div className="col-lg-4 col-md-6">
                                <div className="seo_service_item">
                                    <img src={require('../../img/seo/icon5.png')} alt=""/>
                                    <a href=".#">
                                        <h4>Pronto all'uso</h4>
                                    </a>
                                    <p>Niente setup lunghi, difficili e costosi, lo Staff di Consolidata si occuperà di tutto ed al termine delle configurazioni iniziali ti fornirà gli accessi alle tue nuove dashboard</p>
                                </div>
                            </div>
                        </Fade>
                    </div>
                </div>
            </section>
            <section id="benefits" className="seo_features_one sec_pad">
                <div className="container">
                    <div className="row flex-row-reverse">
                        <div className="col-lg-6">
                            <div className="seo_features_img">
                                <div className="round_circle"></div>
                                <div className="round_circle two"></div>
                                <img src={require('../../img/seo/features_img.png')} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <Fade bottom cascade>
                                <div className="seo_features_content">
                                    <h2>Ottieni gli insights essenziali per far decollare il tuo business.</h2>
                                    <p>I dati di solito sono inconsistenti, spesso insufficienti e normalmente da soli non servono a prendere decisioni. Noi abbiamo la soluzione per farli "parlare" ed aiutarti a compiere scelte strategiche</p>
                                    <div className="media seo_features_item">
                                        <div className="icon"><img src={require('../../img/seo/icon3.png')} alt=""/></div>
                                        <div className="media-body">
                                            <h3>Approvvigionamento dei dati</h3>
                                            <p>Non ti devi preoccupare della base di dati o di come essi verranno scaricati e gestiti</p>
                                        </div>
                                    </div>
                                    <div className="media seo_features_item">
                                        <div className="icon two"><img src={require('../../img/seo/icon4.png')} alt=""/></div>
                                        <div className="media-body">
                                            <h3>Reportistica, analisi e visualizzazione</h3>
                                            <p>I dati sono aggregati e sintetizzati in dashboard. Puoi anche scaricarli in formati compatibili con Excel</p>
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </section>
	    {/*
            <section className="seo_features_one sec_pad">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="seo_features_img seo_features_img_two">
                                <div className="round_circle"></div>
                                <div className="round_circle two"></div>
                                <img src={require('../../img/seo/features_img_two.png')} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 d-flex align-items-center">
                            <Fade bottom cascade>
                                <div className="seo_features_content">
                                    <h2 className="wow fadeInUp">Get tips & tricks on how to skyrocket your sales.</h2>
                                    <h6 className="wow fadeInUp">What a plonker bamboozled so I said say what a load of rubbish owt to do with me haggle.</h6>
                                    <p className="wow fadeInUp">Cheeky bugger gosh codswallop chap bamboozled blatant cracking goal brown bread, pear shaped cor blimey guvnor easy peasy lemon squeezy hotpot spiffing good time, chancer a spend a penny spiffing I don't want no agro tinkety tonk old fruit.</p>
                                    <a href=".#" className="seo_btn seo_btn_one btn_hover wow fadeInUp">Learn More</a>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </section>*/}
        </React.Fragment>
    )
}

export default Service;
