import React from 'react';
import Sectitle from "../../Title/Sectitle";
import Tabitem from "./CloudServiceTabItem";

const CloudServiceTab =()=>{
    return(
        <section id="service-details" className="software_service_area sec_pad">
            <div className="container">
                <Sectitle Title="Passa rapidamente dai dati alle decisioni strategiche." TitleP="BigEye, la piattaforma Web based di Consolidata.tech, è composta da alcuni elementi essenziali."
                 sClass="sec_title text-center mb_70"/>
                <div className="row">
                    <div className="col-lg-3 col-md-3">
                        <ul className="nav nav-tabs software_service_tab" id="myTab" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link active" id="datalake-tab" data-toggle="tab" href="#datalake" role="tab" aria-controls="datalake" aria-selected="true">Datalake e Connettori</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" id="visualization-tab" data-toggle="tab" href="#visualization" role="tab" aria-controls="visualization" aria-selected="false">Data Visualization</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" id="webapp-tab" data-toggle="tab" href="#webapp" role="tab" aria-controls="webapp" aria-selected="true">Layer Applicativo</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" id="ia-tab" data-toggle="tab" href="#ia" role="tab" aria-controls="ia" aria-selected="false">Algoritmi Predittivi</a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-9 col-md-9">
                        <div className="tab-content software_service_tab_content">
                            <div className="tab-pane fade show active" id="datalake" role="tabpanel" aria-labelledby="datalake-tab">
                                <div className="row">
                                    <Tabitem text="Database" description="I dati vengono memorizzati su BigQuery, un database ottimizzato per i BigData, ed assegnati ad uno specifico Brand."/>
                                    <Tabitem colClass="offset-lg-2" text="Tassonomia" description="I dati possono essere riaggregati a vari livelli secondo una tassonomia granulare: Brand > Divisione > Country > Cliente > Agenzia > Media Group."/>
                                    <Tabitem text="Connettori" description="BigEye ha un ecosistema di connettori sviluppati in-house dal team Consolidata."/>
                                    <Tabitem colClass="offset-lg-2" text="Data Quality" description="I dati vengono rielaborati, resi consistenti e ripuliti da errori ed imperfezioni. Vengono anche periodicamente riscaricati per ottenere da ciascuna piattaforma la versione più stabile possibile."/>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="visualization" role="tabpanel" aria-labelledby="visualization-tab">
                                <div className="row">
                                    <Tabitem text="Standard Layer" description="Compreso in tutte le attivazioni, basato su DataStudio."/>
                                    <Tabitem colClass="offset-lg-2" text="Molteplici dashboard" description="Sono presenti dashboard specifiche per Canale ed altre Tematiche che il team Consolidata aggiorna ed amplia periodicamente."/>
                                    <Tabitem text="Custom Layer" description="Realizzato in modo sartoriale sulle specifiche esigenze del cliente, con le tecnologie più adatte allo scopo."/>
                                    <Tabitem colClass="offset-lg-2" text="Nessun limite" description="Il cliente può riversare i dati in un database di sua proprietà o utilizzare il proprio sistema di data visualization per realizzare specifiche viste."/>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="webapp" role="tabpanel" aria-labelledby="webapp-tab">
                                <div className="row">
                                    <Tabitem text="100% Web" description="Il layer applicativo è totalmente basato su tecnologie Web."/>
                                    <Tabitem colClass="offset-lg-2" text="Utenti e permessi" description="Gestione utenti, ruoli, permessi di accesso ai dati e alle singole dashboard."/>
                                    <Tabitem text="Query builder" description="E' disponibile un query builder per estrazioni mirate di dati dal Datalake e la creazione di report specifici."/>
                                    <Tabitem colClass="offset-lg-2" text="Upload dati" description="Upload di dati in diversi formati: CSV, XLS, TXT."/>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="ia" role="tabpanel" aria-labelledby="ia-tab">
                                <div className="row">
                                    <Tabitem text="Previsioni per il business" description="Stiamo sviluppando algoritmi che consentano di prevedere gli andamenti delle campagne pubblicitarie sulla base dei dati storici"/>
                                    <Tabitem colClass="offset-lg-2" text="Algoritmi Statistici" description="Esistono algoritmi della statistica che possono essere impiegati per effettuare previsioni"/>
                                    <Tabitem text="ML e IA" description="La nuova frontiera è però rappresentata dal Machine Learning e dall'Intelligenza Artificiale"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default CloudServiceTab;
